import React, { useContext } from "react"
import { navigate } from "gatsby"
import { miniPlayerContext } from "../../../context/miniPlayer";
import { livePlayerContext } from "../../../context/livePlayer";

const LiveEvent = props => {
  const [miniPlayerState, setminiPlayerState] = useContext(miniPlayerContext);
  const [livePlayerState, setlivePlayerState] = useContext(livePlayerContext);
  return (
    <div className="live-event-block">
      <div className="row">
        <div className="col-sm-8">
          <div className="media">
            <div className="image image--background">
              <img src={props.image1} className="img" alt="Live Event" />
            </div>
          </div>
        </div>
        <div className="col-sm-4">
          <div className="pr-20 pl-xs-20">
            <div className="row">
              <div className="col-md-9">
                <div className="live-event-desc mt-20">
                  <div className="live-event-title font-secondary-bold mb-15 mb-xs-5">
                    {props.time}
                  </div>
                </div>
                <div className="font-secondary-bold">{props.name}</div>
                {!!props.description && (
                  <div
                    className="font-secondary-regular"
                    dangerouslySetInnerHTML={{ __html: props.description }}
                  ></div>
                )}
                <div className="d-xs-flex xs-middle mt-20">
                  <div className="mr-25 mr-xs-20">
                    {props.authorImages.map(value => {
                      return (
                        <img
                          className="radius-circle mr-10 mb-10"
                          src={value.speaker_image_path}
                          height="50px"
                          width="50px"
                          alt={"Image of " + value.speaker_name}
                        />
                      )
                    })}
                  </div>
                </div>
                <div className="mt-20 mb-30 mb-xs-20">
                  <button 
                    className="button font-secondary-bold _a_watchnow"
                    onClick={() => {
                      navigate('/live');
                      if(!miniPlayerState?.showVideoAtBottom || miniPlayerState.isAutoMiniPlayer){
                        const playerObserver = new MutationObserver(() => {
                          const player = document.getElementById("live-stream-player");
                          if (player) {
                            const livePagePlayerEle = document.getElementById("live-page-player");
                            if (livePagePlayerEle) {
                              livePagePlayerEle.appendChild(player);
                              playerObserver.disconnect();
                              setminiPlayerState({ ...miniPlayerState, showVideoAtBottom: false, isAutoMiniPlayer: false });
                            }
                          }
                        });
                        playerObserver.observe(document.body, { childList: true, subtree: true });
                      }
                      setlivePlayerState({ ...livePlayerState, menuTitle: "Live Stream" });
                    }}>
                      Watch now
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default React.memo(LiveEvent)
